import { useContext, useMemo, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useColor } from "components/utils/color";
import { Card } from "react-bootstrap";
import { isEmpty } from "lodash";
import Color from "color";
import { TextWidget } from "../TextWidget";
import cn from "classnames";
import { RowContext } from "components/Layout/Row";
import { RowContextInterface } from "types/RowContextInterface";
import { ColumnContext } from "components/Layout/Column";
import { ColumnContextInterface } from "types/ColumnContextInterface";

import "./styles.scss";

export function AccordionWidget({ accordion, variant }: Readonly<AccordionProps>) {
    const [openItems, setOpenItems] = useState<string[]>([]);

    const columnContext = useContext<ColumnContextInterface>(ColumnContext);
    //@ts-ignore
    const rowContext = useContext<RowContextInterface>(RowContext);

    const rowBackground = rowContext?.backgroundOff ? undefined : rowContext?.backgroundColor;
    const columnBackground = columnContext?.backgroundOff ? undefined : columnContext?.backgroundColor;

    const bodyBackground = useColor(
        columnBackground ?? rowBackground ?? undefined,
        columnContext?.backgroundColorOpacity ?? rowContext?.backgroundColorOpacity ?? undefined
    );

    useMemo(() => {
        if (!isEmpty(accordion)) {
            accordion.forEach((item: AccordionItemProps, index) => {
                if (item.props.state === "open" && !openItems.includes(index.toString())) {
                    setOpenItems([...openItems, index.toString()]);
                }
            });
        }
    }, [accordion, openItems]);

    return (
        <Accordion className="accordion-widget w-100" alwaysOpen flush={variant === "flush"} defaultActiveKey={openItems}>
            {accordion?.map((item: AccordionItemProps, index) => {
                if (item.props.state === "alwaysOpen") {
                    return <AlwaysOpenAccordionItem key={index} index={index.toString()} props={{ ...item?.props, bodyBackground }} />;
                } else {
                    return <AccordionItem key={index} index={index.toString()} props={{ ...item?.props, bodyBackground }} />;
                }
            })}
        </Accordion>
    );
}

function AlwaysOpenAccordionItem({ props }: Readonly<AccordionItemProps>) {
    const headerBackground = useColor(props?.headerBackgroundColor) ?? props.bodyBackground;
    const isHeaderBackgroundLight = Color(headerBackground ?? "#fff")?.isLight();
    const headerTextColor = isHeaderBackgroundLight ? "#333" : "#fff";
    const isBodyBackgroundLight = Color(props?.bodyBackground ?? "#fff")?.isLight();
    const bodyTextColor = isBodyBackgroundLight ? "var(--bs-dark)" : "var(--bs-white)";

    return (
        <Card className="accordion-item">
            <Card.Header
                className={cn("accordion-button", { "light-bg": isHeaderBackgroundLight })}
                style={{ backgroundColor: headerBackground }}
            >
                <span style={{ color: headerTextColor }}>{props.headerText}</span>
            </Card.Header>
            <Card.Body className="accordion-body" style={{ backgroundColor: props?.bodyBackground, color: bodyTextColor }}>
                <TextWidget text={props?.bodyText}></TextWidget>
            </Card.Body>
        </Card>
    );
}

function AccordionItem({ props, index }: Readonly<AccordionItemProps>) {
    const headerBackground = useColor(props?.headerBackgroundColor) ?? props.bodyBackground;
    const isHeaderBackgroundLight = Color(headerBackground ?? "#fff")?.isLight();
    const headerTextColor = isHeaderBackgroundLight ? "#333" : "#fff";
    const isBodyBackgroundLight = Color(props?.bodyBackground ?? "#fff")?.isLight();
    const bodyTextColor = isBodyBackgroundLight ? "var(--bs-dark)" : "var(--bs-white)";

    return (
        <Accordion.Item eventKey={index}>
            <Accordion.Header
                style={{
                    backgroundColor: headerBackground,
                }}
                className={cn({ "light-bg": isHeaderBackgroundLight })}
            >
                <span style={{ color: headerTextColor }}>{props?.headerText}</span>
            </Accordion.Header>
            <Accordion.Body style={{ backgroundColor: props?.bodyBackground, color: bodyTextColor }}>
                <TextWidget text={props?.bodyText} />
            </Accordion.Body>
        </Accordion.Item>
    );
}

type AccordionProps = {
    accordion: AccordionItemProps[];
    variant: string;
};

type AccordionItemProps = {
    props: {
        bodyText?: string;
        headerBackgroundColor?: string;
        headerText?: string;
        state?: string;
        bodyBackground?: string;
    };
    index: string;
    type?: string;
};
