import { getUrl, httpPostAuthorized } from "components/utils/http";
import { toArray, omit, isEmpty, chunk } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGridConfig } from "components/utils/useGridConfig";
import { RelatedApplicationsGrid } from "./RelatedApplicationsGrid";
import useSWR, { KeyedMutator } from "swr";
import { isInIframe } from "components/utils/dom";
import { isNullOrWhitespace } from "components/utils/validation";
import { getBrowserTimezoneOffset } from "components/utils/date";
import { FilterPair, GridResponse, RelatedApplicationListColumnKeys, SearchParams } from "./types";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import { ErrorSummary } from "components/ErrorSummary";
import { getVisibleColumns } from "components/DataGrid/utils";
import { SubmitButton } from "components/Button/SubmitButton";
import { downloadCSV } from "components/utils/files";
import { ErrorSummaryInterface } from "types/ErrorSummary";
import { Icon } from "components/Icon";
import { DataGridConfig } from "components/DataGrid/types";
import useMediaQuery from "components/utils/useMediaQuery";
import { Button } from "components/Button";
import { RelationManagement } from "./RelationManagement";
import { SubmittedAppDataResponse } from "../useApplicationDetails";
import { useHistory } from "react-router";
import { PageLink } from "components/App/utils";
import { Spinner } from "react-bootstrap";

export const RELATED_APPLICATIONS_GRID_ID = "4161BA56-47AF-4C79-B171-CBDDF57CDBE6";

export const RelatedApplications = ({
    applicationNumber,
    projectNumber,
    refreshAppDetails,
    hasRelatedApplications,
}: Readonly<{
    applicationNumber: string;
    projectNumber?: string;
    refreshAppDetails: () => Promise<SubmittedAppDataResponse | undefined>;
    hasRelatedApplications?: boolean;
}>) => {
    const isIFrame = isInIframe();
    const timeOffset = getBrowserTimezoneOffset();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string>("");
    const [sortAsc, setSortAsc] = useState<boolean>(true);
    const [filterPairs, setFilterPairs] = useState<FilterPair[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<ErrorSummaryInterface>();
    const [showRelationsPanel, setShowRelationsPanel] = useState<boolean>(false);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const history = useHistory();

    const onFilterChange = useCallback(
        (newValue: string) => {
            if (newValue !== filter) {
                setFilter(newValue);
                if (!isEmpty(newValue)) {
                    const filterPairs = newValue.split("|").map((f) => {
                        const filterKey = f.split("=")[0];
                        const filterValue = f.split("=")[1];
                        return {
                            field: filterKey,
                            value: filterValue,
                            title: filterKey,
                        };
                    });
                    setFilterPairs(filterPairs);
                } else {
                    setFilter("");
                    setFilterPairs([]);
                }
                setPageNumber(1);
            }
        },
        [filter]
    );

    const onSortChange = (key: string) => {
        if (!isEmpty(sortBy)) {
            if (sortBy === key) {
                setSortAsc((prev) => !prev);
                if (sortAsc) {
                    setSortAsc(false);
                }
            } else {
                setSortBy(key);
                setSortAsc(true);
            }
        } else {
            setSortBy(key);
            setSortAsc(true);
        }
    };

    const [dataGridConfig, isLoadingGridConfig] = useGridConfig(RELATED_APPLICATIONS_GRID_ID);

    const [gridResponse, isLoadingApplications, error, refreshList] = useRelatedApplicationList(
        applicationNumber,
        filterPairs,
        setErrorSummary,
        sortBy,
        sortAsc,
        timeOffset,
        pageNumber,
        pageSize
    );

    const onExportData = async () => {
        let url: any = null;
        const data = [] as any;

        const baseUrl =
            applicationNumber && dataGridConfig && gridResponse
                ? getUrl(process.env.REACT_APP_APPLICATION_RELATIONS_ENDPOINT, { applicationNumber })
                : null;

        if (baseUrl) {
            url = baseUrl + "?" + getQueryParams(1, 10000, getBrowserTimezoneOffset()).toString();
        }

        if (url) {
            try {
                setIsExporting(true);

                const body = getSearchParams(filterPairs, sortBy, sortAsc, applicationNumber);

                const response = await httpPostAuthorized(url, body);

                const gridRows = toArray(response?.grid.rows.length > 1 ? response.grid.rows : [response.grid.rows]);

                const columns = getVisibleColumns(dataGridConfig);

                const rowData = [] as any;

                const header = columns.map((c) => c.name);

                const rowContent = columns.map((c) => {
                    return gridRows
                        .filter((r) => r)
                        .map((r) => {
                            return r && r[c.key];
                        });
                });

                for (let i = 0; i < gridRows.length; i++) {
                    rowContent.forEach((r) => {
                        rowData.push(r[i]);
                    });
                }

                const chunkedRows = [...chunk(rowData, columns.length)];

                // Push into data array for the export
                data.push(header, ...chunkedRows);

                downloadCSV(data, `related-applications-list-${applicationNumber}`);
            } catch (error: any) {
                setErrorSummary(error);
            } finally {
                setIsExporting(false);
            }
        }
    };

    useEffect(() => {
        if (!hasRelatedApplications) {
            history.replace(`${PageLink.ApplicationView}?applicationNumber=${applicationNumber}`);
        }
    }, [hasRelatedApplications, applicationNumber, history]);

    const onRelationsUpdated = useCallback(async () => {
        await refreshList();
        await refreshAppDetails();
    }, [refreshAppDetails, refreshList]);

    const gridConfig = !isIFrame ? dataGridConfig : gridConfigForPortalBuilderPreview;
    const applications = !isIFrame ? gridResponse?.items : applicationsForPortalBuilderPreview;
    const pagesCount = Math.ceil((gridResponse?.totalRecords ?? 0) / pageSize);
    const totalRecords = gridResponse?.totalRecords;

    if (isLoadingApplications && isLoadingGridConfig && !isIFrame) {
        return (
            <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                <span className="visually-hidden">Loading Related Applications...</span>
            </Spinner>
        );
    }

    if (error && !isIFrame) {
        return null;
    }

    return (
        <div className="application-relations d-flex flex-column gap-3">
            <div className="d-flex">
                <h2 className="m-0">Related Applications</h2>
                {!isMobile && (
                    <div className="d-flex flex-row ms-auto gap-2">
                        <Button title="Add Relations" aria-haspopup="dialog" variant="primary" onClick={() => setShowRelationsPanel(true)}>
                            <Icon className="me-2" icon={["fal", "sitemap"]} />
                            Add Relations
                        </Button>
                        <SubmitButton
                            title="Export CSV"
                            variant="secondary"
                            secondaryButtonType="filled"
                            onClick={onExportData}
                            isSubmitting={isExporting}
                            spinnerText="Exporting"
                        >
                            {!isExporting && <Icon className="me-2" icon={["fal", "file-export"]} />}
                            Export CSV
                        </SubmitButton>
                    </div>
                )}
            </div>
            <ErrorSummary className="mt-3" errorSummary={errorSummary} />
            <RelatedApplicationsGrid
                gridConfig={gridConfig}
                items={applications}
                isLoadingGrid={isLoadingGridConfig}
                onFilterChange={onFilterChange}
                sortAsc={sortAsc}
                filter={filter}
                sortBy={sortBy}
                onSortChange={onSortChange}
                onExportData={onExportData}
                isExporting={isExporting}
                applicationNumber={applicationNumber}
                setShowRelationsPanel={setShowRelationsPanel}
                onRelationsUpdated={onRelationsUpdated}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
            />
            <DataGridPaging
                pageNumber={pageNumber}
                pagesCount={pagesCount}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
            />
            {showRelationsPanel && (
                <RelationManagement
                    projectNumber={projectNumber}
                    applicationNumber={applicationNumber}
                    setShowRelationsPanel={setShowRelationsPanel}
                    onRelationsUpdated={onRelationsUpdated}
                />
            )}
        </div>
    );
};

export const useRelatedApplicationList = (
    applicationNumber: string,
    filterPairs: FilterPair[],
    setErrorSummary: any,
    sortBy: string = "",
    sortAsc: boolean = true,
    timeOffset: number = 0,
    pageNumber: number = 1,
    pageSize: number = 10
): [gridResponse: GridResponse | undefined, isLoading: boolean, error: any, refresh: KeyedMutator<any>] => {
    const [response, setResponse] = useState<any>();
    const baseUrl =
        applicationNumber && pageSize > 0 ? getUrl(process.env.REACT_APP_APPLICATION_RELATIONS_ENDPOINT, { applicationNumber }) : null;

    let url: string | null = null;
    if (baseUrl) {
        url = baseUrl + "?" + getQueryParams(pageNumber, pageSize, timeOffset).toString();
    }

    const body = useMemo(
        () => getSearchParams(filterPairs, sortBy, sortAsc, applicationNumber),
        [filterPairs, sortBy, sortAsc, applicationNumber]
    );
    const key = useMemo(() => (isInIframe() ? null : [url, body]), [url, body]);

    const { data, error, mutate } = useSWR(key, () => httpPostAuthorized(url!, body));

    if (error) {
        setErrorSummary(error);
    }

    const isLoading = !error && !data && url !== null;

    // Update data only when not loading anymore. Prevents grid flicker.
    if (!isLoading && response !== data) {
        setResponse(data);
    }

    const result = useMemo(() => {
        let result: GridResponse | undefined = undefined;
        if (!isEmpty(response?.grid?.rows)) {
            const rows = toArray(response?.grid.rows.length > 1 ? response.grid.rows : [response.grid.rows]);

            if (rows?.length > 0) {
                const items = rows.map((i: any) => omit(i, ["MoreRecords", "totRecords"]));
                const totalRecords = Number(rows[0]["totRecords"]);
                const remainingRecords = Number(rows[0]["MoreRecords"]);

                result = {
                    items,
                    totalRecords,
                    remainingRecords,
                };
            }
        }

        return result;
    }, [response]);

    return [result, isLoading, error, mutate];
};

const getQueryParams = (pageNumber: number, pageSize: number, timeOffset: number) => {
    const query = new URLSearchParams();
    query.append("pageNum", String(pageNumber));
    query.append("recsPerPage", String(pageSize));
    query.append("timeOffset", String(timeOffset));

    return query;
};

const getSearchParams = (filterPairs: FilterPair[], sortBy: string, sortAsc: boolean, relSourceAppId: string) => {
    const searchFilter: typeof searchParams.search.searchFilter = [
        {
            fields: {
                searchField: [RelatedApplicationListColumnKeys.relSourceAppId],
            },
            searchValue: [relSourceAppId],
            searchMinValue: "",
            searchMaxValue: "",
        },
    ];

    for (const pair of filterPairs) {
        const searchField = [pair.field];

        let searchValue: string[] = [];
        let searchMinValue = "";
        let searchMaxValue = "";

        searchValue.push(addWildcards(pair.value));

        // Search value cannot be empty.
        if (isEmpty(searchValue)) {
            searchValue = [relSourceAppId];
        }

        if (searchField) {
            searchFilter.push({
                fields: {
                    searchField,
                },
                searchValue,
                searchMinValue,
                searchMaxValue,
            });
        }
    }

    const searchSort = [];

    if (!isEmpty(sortBy)) {
        searchSort.push({
            sortField: sortBy,
            sortAsc: sortAsc ? "1" : "0",
            sortOrder: "1",
        });
    }

    const searchParams: SearchParams = {
        search: {
            searchFilter,
        },
        sort: {
            searchSort,
        },
    };

    return searchParams;
};

const addWildcards = (value: string) => {
    if (isNullOrWhitespace(value)) {
        return value;
    }

    return `*${value.replace(/^\*|\*$/g, "")}*`;
};

const applicationsForPortalBuilderPreview = [
    {
        "c_6a7b2efb-d112-446f-80a7-ad7153f885e3": "Test Program 1",
        "c_7ff65faa-d4a0-44d9-b096-b686bd41747c": "00352258826B4102A15AE78C4E73BEBC",
        "c_9e4a8b70-faa2-4467-bec9-f003f059fcc3": "29F2014F26BC475DA4C4925A628EBA21",
        "c_53aabff2-5a29-4968-9c64-8b62b75f31b7": "D2CCCR1539310943",
        "c_84f179e0-056d-41ed-8008-8bb84beea022": null,
        "c_8131ab88-3e84-4e5b-a6f9-48995b4df7ee": "2023-09-13T13:45:14.187",
        "c_82910f75-a420-4ed3-bf4b-e69ce0d62165": "Application Entry",
    },
    {
        "c_6a7b2efb-d112-446f-80a7-ad7153f885e3": "Test Program 2",
        "c_7ff65faa-d4a0-44d9-b096-b686bd41747c": "21A7157F95414E71838F4128C2637B56",
        "c_9e4a8b70-faa2-4467-bec9-f003f059fcc3": "29F2014F26BC475DA4C4925A628EBA21",
        "c_53aabff2-5a29-4968-9c64-8b62b75f31b7": "D2CCCR1539310943",
        "c_84f179e0-056d-41ed-8008-8bb84beea022": null,
        "c_8131ab88-3e84-4e5b-a6f9-48995b4df7ee": "2023-09-13T13:45:14.187",
        "c_82910f75-a420-4ed3-bf4b-e69ce0d62165": "Application Received",
        hasAccess: 1,
    },
    {
        "c_6a7b2efb-d112-446f-80a7-ad7153f885e3": "Test Program 3",
        "c_7ff65faa-d4a0-44d9-b096-b686bd41747c": "923B5416D5CF4978AB4EAC498EFBD407",
        "c_9e4a8b70-faa2-4467-bec9-f003f059fcc3": "29F2014F26BC475DA4C4925A628EBA21",
        "c_53aabff2-5a29-4968-9c64-8b62b75f31b7": "D2CCCR1539310943",
        "c_84f179e0-056d-41ed-8008-8bb84beea022": null,
        "c_8131ab88-3e84-4e5b-a6f9-48995b4df7ee": "2023-09-13T13:45:14.187",
        "c_82910f75-a420-4ed3-bf4b-e69ce0d62165": "Application Received",
    },
];

const gridConfigForPortalBuilderPreview: DataGridConfig = {
    columns: [
        {
            active: "true",
            datatype: "varchar",
            filter: "true",
            hidecolumn: "true",
            key: "c_7ff65faa-d4a0-44d9-b096-b686bd41747c",
            name: "appid",
            order: "1000",

            sort: undefined,
            type: "text",
        },
        {
            active: "true",
            datatype: "varchar",
            filter: "true",
            hidecolumn: "false",
            key: "c_53aabff2-5a29-4968-9c64-8b62b75f31b7",
            name: "project number",
            order: "1001",
            sort: undefined,
            type: "text",
        },
        {
            active: "true",
            datatype: "varchar",
            filter: "true",
            hidecolumn: "false",
            key: "c_f23d667b-0ff9-49b0-a070-771aa7f1d94d",
            name: "project name",
            order: "1002",
            sort: undefined,
            type: "text",
        },
        {
            active: "true",
            datatype: "varchar",
            filter: "true",
            hidecolumn: "false",
            key: "c_84f179e0-056d-41ed-8008-8bb84beea022",
            name: "contact",
            order: "1003",
            sort: undefined,
            type: "text",
        },
        {
            active: "true",
            datatype: "datetime",
            filter: "true",
            hidecolumn: "false",
            key: "c_8131ab88-3e84-4e5b-a6f9-48995b4df7ee",
            name: "date created",
            order: "1009",
            sort: undefined,
            type: "text",
        },
        {
            active: "true",
            datatype: "varchar",
            filter: "true",
            hidecolumn: "false",
            key: "c_6a7b2efb-d112-446f-80a7-ad7153f885e3",
            name: "program",
            order: "1010",
            sort: undefined,
            type: "text",
        },
        {
            active: "true",
            datatype: "varchar",
            filter: "true",
            hidecolumn: "false",
            key: "c_82910f75-a420-4ed3-bf4b-e69ce0d62165",
            name: "status",
            order: "1011",
            sort: undefined,
            type: "text",
        },
    ],
    pageNumber: 1,
    pageSize: 10,
    pagesCount: 1,
    totalRecords: 3,
};
