import Form from "@rjsf/core";
import { Button } from "components/Button";
import { SubmitButton } from "components/Button/SubmitButton";
import { ErrorSummary } from "components/ErrorSummary";
import { HiddenSubmitButton, JsonForm } from "components/JsonForm";
import { submitByRefPromise } from "components/JsonForm/utils";
import { dataURItoBlob } from "components/utils/files";
import { httpPostAuthorized } from "components/utils/http";
import { useState, useRef } from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { TaskProps } from "./types";

export const FileUploadTask = ({ applicationNumber, task, onComplete, onClose }: TaskProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const formRef = useRef<Form<any>>();

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "string",
                title: "Browse and select the file for upload.",
                format: "data-url",
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:widget": "file",
            "ui:help": "Uploads might take a few moments to complete... Please be patient.",
        },
    };

    const onSubmit = async () => {
        try {
            setIsSubmitting(true);

            const formData = await submitByRefPromise(formRef);
            const fileData = dataURItoBlob(formData.file);

            const body = new FormData();
            body.append("file", fileData.blob, fileData.name);

            const query = new URLSearchParams();
            query.append("entityId", applicationNumber);
            query.append("taskNumber", task.wfTaskNumber);

            await httpPostAuthorized(`${process.env.REACT_APP_APPLICATION_FILE_UPLOAD}?${query.toString()}`, body);

            onComplete(task);
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas show placement="end" backdrop="false" onHide={onClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{task.stepType}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                <ErrorSummary errorSummary={errorSummary} />
                <JsonForm formRef={formRef} schema={schema} uiSchema={uiSchema}>
                    <HiddenSubmitButton />
                </JsonForm>
                <Stack className="mt-auto justify-content-end" direction="horizontal">
                    <SubmitButton onClick={onSubmit} isSubmitting={isSubmitting} spinnerText="Uploading file..." className="me-3">
                        Upload File
                    </SubmitButton>
                    <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
