import { useEffect, useState } from "react";
import { refreshApplicationWorkflow, useApplicationWorkflow } from "components/WorkflowTasks/useApplicationWorkflow";
import { WorkflowFilterStepType, WorkFlowTaskModel, WorkflowTaskStepType } from "components/WorkflowTasks/types";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { formatDateAndTime } from "components/utils/date";
import { isTaskCompleted } from "components/WorkflowTasks/utils";
import { Button } from "components/Button";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Task } from "./Task";
import { ReactComponent as TaskDonePlaceholder } from "../assets/task-done-placeholder.svg";
import { ReactComponent as TaskToDoPlaceholder } from "../assets/task-todo-placeholder.svg";
import { useToast } from "components/Toast";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import cn from "classnames";

const DONE_TASK_LIST_ID = "done-tasks-list";
const TODO_TASK_LIST_ID = "todo-tasks-list";

export const WorkflowTasks = ({ applicationNumber, stepType, onAfterTaskComplete, onChange, onWorkflowLoaded }: WorkflowTasksProps) => {
    const [workflow = [], isLoadingWorkflow] = useApplicationWorkflow(applicationNumber, stepType);
    const toast = useToast();
    const [activeTask, setActiveTask] = useState<WorkFlowTaskModel>();
    const [isDoneTasksExpanded, setIsDoneTasksExpanded] = useState(false);
    const hasDoneTasks = workflow.filter((task) => isTaskCompleted(task)).length > 0;
    const hasTodoTasks = workflow.filter((task) => !isTaskCompleted(task)).length > 0;

    useEffect(() => {
        if (!isLoadingWorkflow) {
            onWorkflowLoaded?.();
        }
    }, [isLoadingWorkflow, onWorkflowLoaded]);

    useEffect(() => {
        onChange?.(workflow);
    }, [onChange, workflow]);

    const onTaskClick = (task: WorkFlowTaskModel) => {
        setActiveTask(task);
    };

    const onTaskComplete = async (task: WorkFlowTaskModel) => {
        sendAnalyticsEvent(AnalyticsEventType.WORKFLOW_TASK_COMPLETED, { type: task.stepType });
        toast.success("Task moved to done");
        await refreshApplicationWorkflow(applicationNumber, stepType);
        onAfterTaskComplete();
        setActiveTask(undefined);
    };

    if (isLoadingWorkflow) {
        return null;
    }

    return (
        <>
            <div className="workflow-tasks d-flex align-items-center">
                <div className="container gap-3 d-flex flex-column">
                    <div className="tasks">
                        <h2 className="m-0 pb-3">
                            To-do{" "}
                            {hasTodoTasks && <span className="h3 m-0">• {workflow.filter((task) => !isTaskCompleted(task)).length}</span>}
                        </h2>
                        {hasTodoTasks ? (
                            <div id={TODO_TASK_LIST_ID} className="gap-2 d-flex flex-column">
                                {workflow
                                    .filter((task) => !isTaskCompleted(task))
                                    .map((task, index) => (
                                        <WorkflowTask key={task.wfTaskNumber} task={task} onTaskClick={onTaskClick}></WorkflowTask>
                                    ))}
                            </div>
                        ) : (
                            <div>
                                <EmptyStatePlaceholder
                                    className="pb-5"
                                    placeholderImage={<TaskToDoPlaceholder />}
                                    title="No pending tasks at the moment"
                                    description="Stay tuned for new tasks to appear here as you progress in your application"
                                />
                            </div>
                        )}
                    </div>
                    {workflow.length > 0 && (
                        <>
                            <hr className="my-0" />
                            <div className="d-flex gap-2 align-items-center">
                                {hasDoneTasks && (
                                    <Button
                                        aria-label={isDoneTasksExpanded ? "Hide done tasks" : "Show done tasks"}
                                        variant="link"
                                        className="text-body"
                                        aria-expanded={isDoneTasksExpanded}
                                        aria-controls={DONE_TASK_LIST_ID}
                                        onClick={() => setIsDoneTasksExpanded(!isDoneTasksExpanded)}
                                    >
                                        <FontAwesomeIcon icon={isDoneTasksExpanded ? "chevron-up" : "chevron-down"} />
                                    </Button>
                                )}
                                <h2 className="m-0">
                                    Done{" "}
                                    {hasDoneTasks && (
                                        <span className="h3 m-0">• {workflow.filter((task) => isTaskCompleted(task)).length}</span>
                                    )}
                                </h2>
                            </div>

                            {hasDoneTasks ? (
                                <Collapse in={isDoneTasksExpanded}>
                                    <div id={DONE_TASK_LIST_ID} className={cn("tasks", { "d-flex flex-column": isDoneTasksExpanded })}>
                                        {workflow
                                            .filter((task) => isTaskCompleted(task))
                                            .map((task) => (
                                                <WorkflowTask key={task.wfTaskNumber} task={task} onTaskClick={onTaskClick}></WorkflowTask>
                                            ))}
                                    </div>
                                </Collapse>
                            ) : (
                                <EmptyStatePlaceholder
                                    className="pb-5"
                                    placeholderImage={<TaskDonePlaceholder />}
                                    title="Your completed tasks will appear here"
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

            {activeTask && applicationNumber && (
                <Task
                    applicationNumber={applicationNumber}
                    task={activeTask}
                    onComplete={onTaskComplete}
                    isTaskCompleted={isTaskCompleted(activeTask)}
                    onClose={() => setActiveTask(undefined)}
                />
            )}
        </>
    );
};

const WorkflowTask = ({ task, onTaskClick }: WorkFlowTaskProps) => {
    let taskTitle = task.stepType;
    let buttonText = task.stepType;
    let buttonIcon: IconProp = "list-check";

    switch (task.stepType) {
        case WorkflowTaskStepType.ApplicationFormFieldEntry:
        case WorkflowTaskStepType.ApplicationFormSectionEntry:
        case WorkflowTaskStepType.ApplicationFormEntry:
            taskTitle = "Submit form info";
            buttonText = "Submit Form";
            buttonIcon = "pen-field";
            break;
        case WorkflowTaskStepType.CreateNewMeasure:
            taskTitle = "Add equipment measure";
            buttonText = "Add Measure";
            buttonIcon = "square-plus";
            break;
        case WorkflowTaskStepType.DocumentUpload:
            taskTitle = "Upload file";
            buttonText = "Upload File";
            buttonIcon = "arrow-up-from-line";
            break;
        default:
            break;
    }

    const clickableContainerProps = isTaskCompleted(task)
        ? {
              role: "button",
              tabIndex: 0,
              onKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => e.key === "Enter" && onTaskClick(task),
              onClick: () => onTaskClick(task),
              "aria-label": taskTitle,
              "aria-describedby": task.wfTaskNumber,
              "data-metadata": JSON.stringify({ name: `Done: ${taskTitle}` }),
          }
        : {};

    return (
        <div
            {...clickableContainerProps}
            aria-haspopup={isTaskCompleted(task) ? "dialog" : undefined}
            className={cn("task d-flex flex-column border rounded-3 border shadow-sm position-relative px-4 pt-3 pb-3", {
                "mb-2": isTaskCompleted(task),
            })}
        >
            <div className="d-flex align-items-center w-100">
                <div>
                    {isTaskCompleted(task) ? (
                        <FontAwesomeIcon icon={["fal", "circle-check"]} size="lg" color="green" />
                    ) : (
                        <FontAwesomeIcon icon={["fal", "circle-dashed"]} size="lg" />
                    )}
                </div>
                <div className="task-content d-flex justify-content-between w-100 align-items-center">
                    <div className="flex-column">
                        <h3 id={task.wfTaskNumber} className="h5 text-body fw-medium m-0 px-3">
                            {taskTitle}
                        </h3>
                        <p className="text-step m-0 px-3 text-wrap">{task.step}</p>
                    </div>
                    {!isTaskCompleted(task) && (
                        <Button
                            variant="primary"
                            className="d-flex align-items-center col-auto"
                            onClick={() => onTaskClick(task)}
                            aria-label={taskTitle}
                            aria-haspopup="dialog"
                            aria-describedby={task.wfTaskNumber}
                        >
                            <FontAwesomeIcon icon={["fal", buttonIcon]} className="py-1" />
                            <div className="button-text py-1 d-none d-md-block ps-2">{buttonText}</div>
                        </Button>
                    )}
                </div>
            </div>
            {isTaskCompleted(task) && task?.dateCompleted && (
                <>
                    <hr className="my-2" />
                    <p className="m-0 small">Completed on {formatDateAndTime(task?.dateCompleted)}</p>
                </>
            )}
            <div className={cn("detail position-absolute top-0 start-0 h-100", { complete: isTaskCompleted(task) })} />
        </div>
    );
};

interface WorkflowTasksProps {
    applicationNumber?: string;
    stepType?: WorkflowFilterStepType;
    onAfterTaskComplete: () => void;
    onChange?: (workflow: WorkFlowTaskModel[]) => void;
    onWorkflowLoaded?: () => void;
}

interface WorkFlowTaskProps {
    task: WorkFlowTaskModel;
    onTaskClick: (task: WorkFlowTaskModel) => void;
}
