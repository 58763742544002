import { toArray } from "components/utils/array";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWRImmutable from "swr/immutable";
import { EquipmentAttribute, EquipmentDetails, EquipmentDetailsFromCatalog } from "./types";

export const useEquipmentDetailsFromCatalog = (
    applicationNumber?: string,
    catalogNumber?: string
): [equipmentDetails: EquipmentDetailsFromCatalog, isLoading: boolean, error: any, isValidating: boolean] => {
    const url =
        applicationNumber && catalogNumber
            ? getUrl(process.env.REACT_APP_EQUIPMENT_CATALOG_ENDPOINT, { applicationNumber, catalogNumber })
            : null;

    const { data, error, isValidating } = useSWRImmutable(url, httpGetAuthorized, {
        revalidateOnMount: true,
    });
    const isLoading = isValidating || (!error && !data && url !== null);

    const equipmentDetails = data?.equipment
        ? {
              ...data?.equipment,
              quantity: data?.equipment.quantity ? Number(data?.equipment.quantity) : undefined,
              quantityFriendlyName: data?.equipment?.quantityFriendlyName,
              attributes: toArray(data?.equipment?.attributes?.attributeItem).map((a) => ({
                  attributeName: a.attributeName,
                  friendlyName: a.friendlyName,
                  itemOrder: Number(a.itemOrder),
                  validationType: Number(a.validationType),
                  validationRequired: Number(a.ValidationRequired),
                  showAll: a.showAll === "1",
                  editAll: a.editAll === "1",
                  isCalculated: a.isCalculated === "1",
                  lookupValues: toArray(a?.lookupValues?.lookupValue),
                  defaultValue: a.defaultValue,
                  lowerLimit: a.lowerLimit,
                  upperLimit: a.upperLimit,
                  toolTip: a.toolTip,
              })),
          }
        : data;

    return [equipmentDetails, isLoading, error, isValidating];
};

export const useEquipmentDetails = (
    applicationNumber?: string,
    equipmentNumber?: string
): [equipmentDetails: EquipmentDetails, isLoading: boolean, error: any] => {
    const url =
        applicationNumber && equipmentNumber
            ? getUrl(process.env.REACT_APP_EQUIPMENT_ENDPOINT, { applicationNumber }) + `/${equipmentNumber}`
            : null;

    const { data, error, isValidating } = useSWRImmutable(url, httpGetAuthorized, {
        revalidateOnMount: true,
    });
    const isLoading = isValidating || (!error && !data && url !== null);

    const equipmentDetails: EquipmentDetails = data?.equipment
        ? {
              ...data?.equipment,
              quantity: data?.equipment.quantity ? Number(data?.equipment.quantity) : undefined,
              attributes: toArray(data?.equipment.attributes).map((a) => {
                  const attribute: EquipmentAttribute = {
                      attributeName: a.attributename,
                      friendlyName: a.friendlyName,
                      itemOrder: Number(a.itemOrder),
                      validationType: Number(a.validationType),
                      validationRequired: Number(a.ValidationRequired),
                      showAll: a.showAll === "1",
                      editAll: a.editAll === "1",
                      isCalculated: a.isCalculated === "1",
                      lookupValues: toArray(a?.lookupValues?.lookupValue),
                      hasApproveMeasure: a.hasApproveMeasure === "1",
                      productStandard: a.productStandard,
                      value: a.value,
                      defaultValue: a.defaultValue,
                      lowerLimit: a.lowerLimit,
                      upperLimit: a.upperLimit,
                      toolTip: a.toolTip,
                  };

                  return attribute;
              }),
          }
        : data;

    return [equipmentDetails, isLoading, error];
};
