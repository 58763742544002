import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import { ExternalLinkIcon } from "components/Icon/ExternalLinkIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageConfiguration } from "types/PageConfiguration";
import { isInternalLink } from "components/utils/links";
import { ExternalLink } from "components/ExternalLink";

import "./style.scss";

export const MenuItem = ({ linkClassName, page, linkStyle, linkWrapperStyle, wrapperClassName }: MenuItemProps) => {
    const isInternal = isInternalLink(page.link);
    const className = cn("nav-link", linkClassName);

    const location = useLocation();

    const linkParts = (page.link ?? "").split("?");
    const pathname = linkParts[0];
    const search = linkParts.length > 1 ? `?${linkParts[1]}` : undefined;

    return (
        <li style={linkWrapperStyle} className={cn("nav-item ignore-padding-on-mobile flex-grow-0 text-nowrap", wrapperClassName)}>
            {isInternal && (
                <Link
                    className={className}
                    to={{ pathname, search, state: location.state }}
                    style={linkStyle}
                    target={page.openInNewTab ? "_blank" : "_self"}
                >
                    <span>
                        {page.title}
                        {page.openInNewTab && <ExternalLinkIcon className={cn(linkClassName)} />}
                    </span>
                    <FontAwesomeIcon className="ms-2 d-md-none" icon={"chevron-right"} />
                </Link>
            )}
            {!isInternal && (
                <ExternalLink className={className} href={page.link} openInNewTab={page.openInNewTab} style={linkStyle}>
                    {page.title}
                </ExternalLink>
            )}
        </li>
    );
};

interface MenuItemProps {
    linkClassName?: string;
    wrapperClassName?: string;
    page: PageConfiguration;
    linkStyle: React.CSSProperties;
    linkWrapperStyle: React.CSSProperties;
}
