import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import { HeadingLevel } from "types/HeadingLevel";
import { ElementTypes } from "../utils";

export const ObjectFieldTemplate = (props) => {
    const { DescriptionField, description, TitleField, properties, required, schema, uiSchema, idSchema } = props;

    const fieldTitle = uiSchema["ui:title"] ?? schema.title;
    const elementType = uiSchema["ui:elementType"];
    const titleHeadingLevel = uiSchema["ui:headingLevel"] ?? getTitleHeadingLevel(elementType);

    let propertiesContent = (
        <Container fluid className="p-0 d-flex flex-column gap-3">
            {properties.map((element, index) => (
                <Row
                    key={index}
                    className={classNames({
                        "d-none": element.hidden,
                    })}
                >
                    <Col xs={12}> {element.content}</Col>
                </Row>
            ))}
        </Container>
    );

    if (elementType === ElementTypes.SECTION) {
        propertiesContent = (
            <Container fluid className="p-0">
                <Row className="justify-content-md-center">
                    {properties.map((element, index) => (
                        <Col
                            sm
                            key={index}
                            className={classNames({
                                "d-none": element.hidden,
                            })}
                        >
                            {element.content}
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }

    return (
        <>
            {fieldTitle && (
                <TitleField id={`${idSchema.$id}-title`} title={fieldTitle} headingLevel={titleHeadingLevel} required={required} />
            )}
            {description && <DescriptionField id={`${idSchema.$id}-description`} description={description} />}
            {propertiesContent}
        </>
    );
};

const getTitleHeadingLevel = (elementType) => {
    let headingLevel = HeadingLevel.H5;

    switch (elementType) {
        case ElementTypes.PAGE:
            headingLevel = HeadingLevel.H1;
            break;
        case ElementTypes.SECTION:
            headingLevel = HeadingLevel.H4;
            break;
        case ElementTypes.COLUMN:
            headingLevel = HeadingLevel.H5;
            break;
        default:
            break;
    }

    return headingLevel;
};
