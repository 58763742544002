import React, { useState } from "react";
import { WidgetProps } from "@rjsf/core";
import { HelpModalWidget } from "components/Widgets/HelpModal";
import { RawHTML } from "components/RawHTML";
import { Icon } from "components/Icon";

type Props = Pick<WidgetProps, "uiSchema">;

const HelpModalToggleWidget = ({ uiSchema }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const helpModalContent = uiSchema["ui:helpModalContent"];
    const helpModalIcon = uiSchema["ui:helpModalIcon"];
    const helpModalTitle = uiSchema["ui:helpModalTitle"];

    const toggleHelpModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setShowModal((showModal) => !showModal);
    };

    return (
        <>
            {helpModalIcon?.length > 0 && helpModalContent?.length > 0 && (
                <span>
                    <button title="Show help modal" className="text-reset bg-transparent border border-0" onClick={toggleHelpModal}>
                        <Icon icon={["far", helpModalIcon]} />
                    </button>
                </span>
            )}
            <HelpModalWidget
                title={helpModalTitle}
                content={<RawHTML>{helpModalContent}</RawHTML>}
                icon={helpModalIcon}
                show={showModal}
                onHide={() => setShowModal(false)}
            />
        </>
    );
};

export default HelpModalToggleWidget;
