import Form from "@rjsf/core";
import { getSchemaIds, listToAnyOf } from "components/JsonForm/utils";
import {
    isEmail,
    isPhoneNumber,
    isPostalCode,
    REQUIRED_FIELD_ERROR_TEXT,
    REQUIRED_LAST_AND_FIRST_OR_CONTACT,
} from "components/utils/validation";
import { formatPhoneNumber } from "components/utils/format";
import { get, isEmpty, isEqual, omit, omitBy, set } from "lodash";
import { MutableRefObject } from "react";
import { ReferenceResult } from "types/ReferenceResult";
import {
    ContactValidationResult,
    FormState,
    ApplicationContactsWidgetValidationResult,
    ContactFormData,
    ContractorFormData,
    ContactInboundData,
    ApplicationContactsWidgetValidationParams,
    ContactTypeCode,
    AdditionalContactType,
} from "./types";
import { ContactType, ContactData, getRequiredFieldsByContactType, isContactDataEmpty, ContractorData } from "components/utils/contacts";
import { RequireContact } from "../../../../../types/RequireContact";
import { ContactRequirements } from "types/ContactRequirements";

export const getFormRefValues = (formRef: Form<any> | undefined) => {
    if (!formRef) {
        return undefined;
    }

    return getFormValues(formRef.state);
};

export const getFormValues = (form: any | undefined) => {
    if (!form) {
        return undefined;
    }

    const { formData, schema } = form as FormState;

    const fieldValues = getSchemaIds(schema, true).reduce((result: any, item) => {
        const schemaId = item.id;
        const value = get(formData, schemaId);
        const key = schemaId.split(".").pop();

        if (key) {
            result[key] = typeof value === "string" ? value?.trim() : value;
        }

        return result;
    }, {});

    return fieldValues;
};

export const createContactFormDataObject = (contact: ContactInboundData | undefined): ContactFormData | undefined => {
    if (!contact) {
        return undefined;
    }

    return {
        section: {
            column1: {
                contactType: contact.contactType,
                contactTitle: contact.contactTitle,
                firstName: contact.firstName,
                lastName: contact.lastName,
                company: contact.company,
                premiseId: contact.premiseId,
                meterId: contact.meterid,
                acct_number: contact.accountNumber,
            },
            column2: {
                address: contact.address,
                address_cont: contact.address_cont,
                city: contact.city,
                state: contact.state,
                zip: contact.zip,
                phone: formatPhoneNumber(contact.phone),
                cell: formatPhoneNumber(contact.cell),
                fax: formatPhoneNumber(contact.fax),
                email: contact.email,
                taxId: contact.taxId,
            },
        },
    };
};

export const createContractorFormDataObjectFromContact = (contractor: ContactInboundData | undefined): ContractorFormData | undefined => {
    if (!contractor) {
        return undefined;
    }

    return {
        section: {
            column1: {
                contactTitle: contractor.contactTitle,
                company: contractor.company ?? undefined,
                firstName: contractor.firstName ?? undefined,
                lastName: contractor.lastName ?? undefined,
                premiseId: contractor.premiseId,
                meterId: contractor.meterid,
                acct_number: contractor.accountNumber,
            },
            column2: {
                address: contractor.address ?? undefined,
                address_cont: contractor.address_cont,
                city: contractor.city ?? undefined,
                state: contractor.state ?? undefined,
                zip: contractor.zip ?? undefined,
                phone: formatPhoneNumber(contractor.phone) ?? undefined,
                cell: formatPhoneNumber(contractor.cell),
                fax: formatPhoneNumber(contractor.fax),
                email: contractor.email ?? undefined,
                taxId: contractor.taxId,
            },
        },
        contactNumber: contractor.contactNumber ?? undefined,
        origId: contractor.origId ?? undefined,
    };
};

export const createContractorFormDataObject = (contractor: ContractorData): ContractorFormData | undefined => {
    if (!contractor) {
        return undefined;
    }

    return {
        section: {
            column1: {
                contactTitle: contractor.contactTitle,
                company: contractor.company ?? undefined,
                firstName: contractor.firstName ?? undefined,
                lastName: contractor.lastName ?? undefined,
                premiseId: contractor.premiseId ?? undefined,
                meterId: contractor.meterId ?? undefined,
                acct_number: contractor.acct_number ?? undefined,
                technologies: contractor.technologies ?? undefined,
                services: contractor.services ?? undefined,
            },
            column2: {
                address: contractor.address ?? undefined,
                address_cont: contractor.address_cont ?? undefined,
                city: contractor.city ?? undefined,
                state: contractor.state ?? undefined,
                zip: contractor.zip ?? undefined,
                phone: contractor.phone ?? undefined,
                cell: contractor.cell ?? undefined,
                fax: contractor.fax ?? undefined,
                email: contractor.email ?? undefined,
                taxId: contractor.taxId ?? undefined,
            },
        },
        contactNumber: contractor.contactNumber ?? undefined,
        origId: contractor.origId ?? undefined,
    };
};

export const getContactTypeByName = (contactTypes: ReferenceResult[], contactTypeName: string): number | undefined => {
    const value = contactTypes.find((t) => String(t.display).toLowerCase() === contactTypeName.toLowerCase())?.val;

    if (value) {
        return Number(value);
    }

    return undefined;
};

const conactTypeOptions = (contractorLabel: string) => [
    {
        const: AdditionalContactType.Stored,
        title: "Your Saved Contacts",
    },
    {
        const: AdditionalContactType.Contractor,
        title: `Available ${contractorLabel} Contacts`,
    },
];

export const getContactSchema = (
    title: string,
    description: string | undefined,
    requiredFields: string[],
    hiddenFields: string[],
    showCopyContact: boolean = false,
    customerContacts: ContactInboundData[] = [],
    requirements: ContactRequirements,
    isContactsView: boolean = false,
    isEditMode: boolean = false,
    showContractorSearch = false,
    isAdditionalContact = false,
    contractorLabel: string = "Contractor"
) => {
    const column1AllFields = {
        ...(isContactsView
            ? {
                  contactType: {
                      type: "string",
                      title: "Choose contact type",
                      anyOf: [
                          { title: "Primary", enum: [ContactTypeCode.Primary] },
                          { title: "Premise", enum: [ContactTypeCode.Premise] },
                          { title: "Contractor", enum: [ContactTypeCode.Contractor] },
                      ],
                  },
                  contactTitle: {
                      type: "string",
                      title: "Name this contact",
                  },
              }
            : {}),
        firstName: {
            type: "string",
            title: "First Name",
        },
        lastName: {
            type: "string",
            title: "Last Name",
        },
        company: {
            type: "string",
            title: "Company",
        },
        premiseId: {
            type: "string",
            title: "Premise ID",
        },
        meterId: {
            type: "string",
            title: "Meter ID",
        },
        acct_number: {
            type: "string",
            title: "Account Number",
        },
    };

    const column2AllFields = {
        address: {
            type: "string",
            title: "Address",
        },
        address_cont: {
            type: "string",
            title: "Address (cont)",
        },
        city: {
            type: "string",
            title: "City",
        },
        state: {
            type: "string",
            title: "State/Province",
        },
        zip: {
            type: "string",
            title: "Postal Code",
            maxLength: 10,
        },
        phone: {
            type: "string",
            title: "Phone",
        },
        cell: {
            type: "string",
            title: "Cell",
        },
        fax: {
            type: "string",
            title: "Fax",
        },
        email: {
            type: "string",
            title: "Email",
            format: "email",
        },
        taxId: {
            type: "string",
            title: "Tax ID",
        },
    };

    const column1RequiredFields = Object.keys(column1AllFields).filter((k) => requiredFields.includes(k.toLowerCase()));
    const column2RequiredFields = Object.keys(column2AllFields).filter((k) => requiredFields.includes(k.toLowerCase()));

    const column1Fields = omitBy(column1AllFields, (value, key) => hiddenFields.includes(key.toLowerCase()));
    const column2Fields = omitBy(column2AllFields, (value, key) => hiddenFields.includes(key.toLowerCase()));

    const copyContactSchema = showCopyContact
        ? {
              copyContact: {
                  type: "boolean",
                  title: "Same as home/business information",
              },
          }
        : {};

    return {
        type: "object",
        title: title,
        description: description,
        properties: {
            ...(showContractorSearch && isAdditionalContact
                ? {
                      _contactType: {
                          type: "number",
                          title: "",
                          oneOf: conactTypeOptions(contractorLabel),
                      },
                  }
                : {}),
            ...copyContactSchema,
            ...(isEditMode ? {} : getStoredContactSchema(customerContacts, requirements)),
            _requirementsInfo: {
                type: ["string", "null"],
                title: "",
            },
            section: {
                type: "object",
                title: "",
                properties: {
                    column1: {
                        type: "object",
                        title: "",
                        required: column1RequiredFields,
                        properties: column1Fields,
                    },
                    column2: {
                        type: "object",
                        title: "",
                        required: column2RequiredFields,
                        properties: column2Fields,
                    },
                },
            },
            ...(isContactsView
                ? {}
                : {
                      saveContact: {
                          type: "boolean",
                          title: "Save this as a new contact in my profile contact list",
                      },
                  }),
        },
    };
};

export const getContractorSchema = (
    title: string,
    description: string,
    requiredFields: string[],
    hiddenFields: string[],
    showContractorSearch: boolean,
    showForm: boolean,
    requirements: ContactRequirements,
    customerContacts: ContactInboundData[] = [],
    isAdditionalContact = false,
    contractorLabel: string = "Contractor"
) => {
    const column1AllFields = {
        company: {
            type: "string",
            title: "Company",
        },
        firstName: {
            type: "string",
            title: "First Name",
        },
        lastName: {
            type: "string",
            title: "Last Name",
        },
        premiseId: {
            type: "string",
            title: "Premise ID",
        },
        meterId: {
            type: "string",
            title: "Meter ID",
        },
        acct_number: {
            type: "string",
            title: "Account Number",
        },
    };

    const column2AllFields = {
        address: {
            type: "string",
            title: "Address",
        },
        address_cont: {
            type: "string",
            title: "Address (cont)",
        },
        city: {
            type: "string",
            title: "City",
        },
        state: {
            type: "string",
            title: "State/Province",
        },
        zip: {
            type: "string",
            title: "Postal Code",
            maxLength: 10,
        },
        phone: {
            type: "string",
            title: "Phone",
        },
        cell: {
            type: "string",
            title: "Cell",
        },
        fax: {
            type: "string",
            title: "Fax",
        },
        email: {
            type: "string",
            title: "Email",
            format: "email",
        },
        taxId: {
            type: "string",
            title: "Tax ID",
        },
    };

    const column1RequiredFields = Object.keys(column1AllFields).filter((k) => requiredFields.includes(k.toLowerCase()));
    const column2RequiredFields = Object.keys(column2AllFields).filter((k) => requiredFields.includes(k.toLowerCase()));

    const column1Fields = omitBy(column1AllFields, (value, key) => hiddenFields.includes(key.toLowerCase()));
    const column2Fields = omitBy(column2AllFields, (value, key) => hiddenFields.includes(key.toLowerCase()));

    return {
        type: "object",
        title: title,
        description: description,
        properties: {
            ...(showContractorSearch && isAdditionalContact
                ? {
                      _contactType: {
                          type: "number",
                          title: "",
                          oneOf: conactTypeOptions(contractorLabel),
                      },
                  }
                : {}),
            ...(showContractorSearch ? { contractorSearch: { type: "string" } } : {}),
            ...(showForm
                ? {
                      ...getStoredContactSchema(customerContacts, requirements, true),
                      ...(showContractorSearch
                          ? {}
                          : {
                                _requirementsInfo: {
                                    type: ["string", "null"],
                                    title: "",
                                },
                            }),
                      section: {
                          type: "object",
                          title: "",
                          properties: {
                              column1: {
                                  type: "object",
                                  title: "",
                                  required: column1RequiredFields,
                                  properties: column1Fields,
                              },
                              column2: {
                                  type: "object",
                                  title: "",
                                  required: column2RequiredFields,
                                  properties: column2Fields,
                              },
                          },
                      },
                      contactNumber: {
                          type: "string",
                      },
                      origId: {
                          type: "string",
                      },

                      saveContact: {
                          type: "boolean",
                          title: "Save this as a new contact in my profile contact list",
                      },
                  }
                : {}),
        },
    };
};

const getStoredContactSchema = (customerContacts: ContactInboundData[], requirements: ContactRequirements, isContractor = false) => {
    if (requirements !== undefined) {
        return customerContacts.length > 0 &&
            isContractor &&
            (requirements.requireContractor === RequireContact.NotRequiredFromList ||
                requirements.requireContractor === RequireContact.RequiredFromList)
            ? {}
            : {
                  storedContactNumber: {
                      type: "string",
                      title: "Choose existing contact",
                      anyOf: listToAnyOf({
                          list: customerContacts,
                          map: (c: ContactInboundData) => ({ title: getContactName(c), enum: [c.contactNumber] }),
                      }),
                  },
              };
    } else {
        return customerContacts.length > 0
            ? {
                  storedContactNumber: {
                      type: "string",
                      title: "Choose existing contact",
                      anyOf: customerContacts.map((c) => ({
                          title: getContactName(c),
                          enum: [c.contactNumber],
                      })),
                  },
              }
            : {};
    }
};

export const validate = ({
    primaryContactFormRef,
    premiseContactFormRef,
    contractorContactFormRef,
    requirements,
    isAppSubmitted,
    contractorLabel,
}: ApplicationContactsWidgetValidationParams): ApplicationContactsWidgetValidationResult => {
    let premiseContactErrors;
    let primaryContactErrors;
    let contractorContactErrors;

    const premiseContactValues = getFormRefValues(premiseContactFormRef?.current);
    const primaryContactValues = getFormRefValues(primaryContactFormRef?.current);

    if (
        premiseContactFormRef?.current &&
        (!isContactDataEmpty(premiseContactValues) || requirements.requirePremise === RequireContact.Required || isAppSubmitted)
    ) {
        const requiredFields = getRequiredFieldsByContactType(requirements.requiredFields, ContactType.Premise);
        premiseContactErrors = validateContact(requiredFields, getFormRefValues(premiseContactFormRef.current));
    }

    if (
        primaryContactFormRef?.current &&
        (!isContactDataEmpty(primaryContactValues) || requirements.requirePrimaryContact === RequireContact.Required || isAppSubmitted)
    ) {
        const requiredFields = getRequiredFieldsByContactType(requirements.requiredFields, ContactType.Primary);
        primaryContactErrors = validateContact(requiredFields, getFormRefValues(primaryContactFormRef.current));
    }

    if (contractorContactFormRef?.current) {
        const formValues = getFormRefValues(contractorContactFormRef.current);

        // Do not try to validate the whole form if there is only a search button or if the contractor has been selected from the list
        if (!formValues.contactNumber && requirements.requireContractor === RequireContact.NotRequiredFromList) {
            // This is a check to make sure that if a contractor is not required it does not prevent the form from continuing
        } else if (
            (!isContactDataEmpty(formValues) ||
                (isContactDataEmpty(formValues) && isAppSubmitted) ||
                requirements.requireContractor === RequireContact.Required) &&
            !(
                formValues.contactNumber &&
                (requirements.requireContractor === RequireContact.RequiredFromList ||
                    requirements.requireContractor === RequireContact.NotRequiredFromList)
            )
        ) {
            contractorContactErrors = validateContractor(ContactType.Contractor, requirements, formValues);
        }

        // Add error if required contractor is not selected
        if ([RequireContact.RequiredFromList].includes(requirements.requireContractor!) && Object.values(formValues).every(isEmpty)) {
            if (!contractorContactErrors) {
                contractorContactErrors = {};
            }
            set(contractorContactErrors, "contractorSearch.__errors[0]", `${contractorLabel} is Required`);
        }
    }

    return {
        primaryContactErrors,
        premiseContactErrors,
        contractorContactErrors,
    };
};

export const validateDataFormat = ({
    primaryContactFormRef,
    premiseContactFormRef,
    contractorContactFormRef,
}: ApplicationContactsWidgetValidationParams): ApplicationContactsWidgetValidationResult => {
    let premiseContactErrors;
    let primaryContactErrors;
    let contractorContactErrors;

    if (premiseContactFormRef?.current) {
        premiseContactErrors = validateContactDataFormat(getFormRefValues(premiseContactFormRef.current));
    }

    if (primaryContactFormRef?.current) {
        primaryContactErrors = validateContactDataFormat(getFormRefValues(primaryContactFormRef.current));
    }

    if (contractorContactFormRef?.current) {
        contractorContactErrors = validateContactDataFormat(getFormRefValues(contractorContactFormRef.current));
    }

    return {
        primaryContactErrors,
        premiseContactErrors,
        contractorContactErrors,
    };
};

export const validateContact = (requiredFields: string[], contact: ContactData): ContactValidationResult => {
    let errors = {};

    if (!contact) {
        return undefined;
    }

    const validateRequiredField = (fieldKey: keyof ContactData, columnKey: string) => {
        if (requiredFields.includes(fieldKey.toLowerCase()) && isEmpty(contact[fieldKey]?.toString().trim())) {
            set(errors, `section.${columnKey}.${fieldKey}.__errors[0]`, REQUIRED_FIELD_ERROR_TEXT);
        }
    };

    // first name
    if (isEmpty(contact.firstName?.trim()) && isEmpty(contact.company?.trim())) {
        let errorFieldKey = "";

        // Get visible required field key
        if (requiredFields.includes("firstname")) {
            errorFieldKey = "firstName";
        } else if (requiredFields.includes("lastname")) {
            errorFieldKey = "lastName";
        } else {
            errorFieldKey = "company";
        }

        set(errors, `section.column1.${errorFieldKey}.__errors[0]`, REQUIRED_LAST_AND_FIRST_OR_CONTACT);
    }

    // last name
    if (isEmpty(contact.lastName?.trim())) {
        if (isEmpty(contact.company?.trim()) && !isEmpty(contact.firstName?.trim())) {
            let errorFieldKey = "";

            // Get visible required field key
            if (requiredFields.includes("lastname")) {
                errorFieldKey = "lastName";
            } else if (requiredFields.includes("firstname")) {
                errorFieldKey = "firstName";
            } else {
                errorFieldKey = "company";
            }

            set(errors, `section.column1.${errorFieldKey}.__errors[0]`, REQUIRED_LAST_AND_FIRST_OR_CONTACT);
        }
    }

    validateRequiredField("contactType", "column1");
    validateRequiredField("premiseId", "column1");
    validateRequiredField("meterId", "column1");
    validateRequiredField("acct_number", "column1");

    validateRequiredField("address", "column2");
    validateRequiredField("address_cont", "column2");
    validateRequiredField("city", "column2");
    validateRequiredField("state", "column2");
    validateRequiredField("zip", "column2");
    validateRequiredField("phone", "column2");
    validateRequiredField("cell", "column2");
    validateRequiredField("fax", "column2");
    validateRequiredField("taxId", "column2");
    validateRequiredField("email", "column2");

    validateContactDataFormat(contact, errors);

    return isEmpty(errors) ? undefined : errors;
};

const validateContractor = (
    contactType: ContactType,
    requirements: ContactRequirements,
    contact: ContractorData
): ContactValidationResult => {
    let errors = {};

    if (!contact) {
        return undefined;
    }

    const requiredFields = getRequiredFieldsByContactType(requirements.requiredFields, contactType);

    const validateRequiredField = (fieldKey: keyof ContractorData, columnKey: string) => {
        if (requiredFields.includes(fieldKey.toLowerCase()) && isEmpty(contact[fieldKey])) {
            set(errors, `section.${columnKey}.${fieldKey}.__errors[0]`, REQUIRED_FIELD_ERROR_TEXT);
        }
    };

    if (requiredFields.includes("firstname") || requiredFields.includes("company") || requiredFields.includes("lastname")) {
        // first name
        if (isEmpty(contact.firstName) && isEmpty(contact.company)) {
            set(errors, "section.column1.firstName.__errors[0]", REQUIRED_LAST_AND_FIRST_OR_CONTACT);
        }

        // last name
        if (isEmpty(contact.lastName)) {
            if (isEmpty(contact.company) && !isEmpty(contact.firstName)) {
                set(errors, "section.column1.lastName.__errors[0]", REQUIRED_LAST_AND_FIRST_OR_CONTACT);
            }
        }
    }

    validateRequiredField("premiseId", "column1");
    validateRequiredField("meterId", "column1");
    validateRequiredField("acct_number", "column1");

    validateRequiredField("address", "column2");
    validateRequiredField("address_cont", "column2");
    validateRequiredField("city", "column2");
    validateRequiredField("state", "column2");
    validateRequiredField("zip", "column2");
    validateRequiredField("phone", "column2");
    validateRequiredField("cell", "column2");
    validateRequiredField("fax", "column2");
    validateRequiredField("taxId", "column2");

    if (requiredFields.includes("email")) {
        validateRequiredField("email", "column2");
    }

    validateContactDataFormat(contact, errors);

    return isEmpty(errors) ? undefined : errors;
};

export const validateContactDataFormat = (
    contact: ContactData | ContractorData,
    errors: ContactValidationResult = {}
): ContactValidationResult => {
    if (!contact) {
        return undefined;
    }

    if (!isEmpty(contact.phone) && !isPhoneNumber(contact.phone!)) {
        set(errors, "section.column2.phone.__errors[0]", "Enter phone number in the correct format, like (000) 000-000 or 000-000-0000");
    }

    if (!isEmpty(contact.cell) && !isPhoneNumber(contact.cell!)) {
        set(
            errors,
            "section.column2.cell.__errors[0]",
            "Enter cell phone number in the correct format, like (000) 000-000 or 000-000-0000"
        );
    }

    if (!isEmpty(contact.fax) && !isPhoneNumber(contact.fax!)) {
        set(errors, "section.column2.fax.__errors[0]", "Enter fax number in the correct format, like (000) 000-000 or 000-000-0000");
    }

    // email
    if (!isEmpty(contact.email) && !isEmail(contact.email ?? "")) {
        set(errors, "section.column2.email.__errors[0]", "Enter an email address in the correct format, like name@example.com");
    }

    // postal code
    if (!isEmpty(contact.zip) && !isPostalCode(contact.zip ?? "")) {
        set(errors, "section.column2.zip.__errors[0]", "Enter a postal code in the correct format, like 00000, 00000-0000, or A0A 0A0");
    }

    return isEmpty(errors) ? undefined : errors;
};

/**
 *  Return "copy contact" checkbox state.
 *
 * @param premiseContactFormRef Premise contact form ref
 * @param primaryContactFormRef Primary contact form ref
 * @returns  Return true if both contact forms have some data and are equal. Otherwise, false.
 */
export const getCopyContactState = (premiseContactFormRef: MutableRefObject<Form<any> | undefined>, primaryContactForm: any) => {
    const premiseContactData = omit(getFormRefValues(premiseContactFormRef.current), [
        "copyContact",
        "saveContact",
        "storedContactNumber",
    ]) as ContactData;
    const primaryContactData = omit(getFormValues(primaryContactForm), [
        "copyContact",
        "saveContact",
        "storedContactNumber",
    ]) as ContactData;

    return (
        isEqual(premiseContactData, primaryContactData) &&
        !Object.values(premiseContactData).every(isEmpty) &&
        !Object.values(primaryContactData).every(isEmpty)
    );
};

export const copyPremiseToPrimaryContact = (premiseContactFormRef: MutableRefObject<Form<any> | undefined>) => {
    const premiseContactFormData = getFormRefValues(premiseContactFormRef.current) as ContactData;
    return {
        section: {
            column1: {
                firstName: premiseContactFormData.firstName,
                lastName: premiseContactFormData.lastName,
                company: premiseContactFormData.company,
                premiseId: premiseContactFormData.premiseId,
                meterId: premiseContactFormData.meterId,
                acct_number: premiseContactFormData.acct_number,
            },
            column2: {
                address: premiseContactFormData.address,
                address_cont: premiseContactFormData.address_cont,
                city: premiseContactFormData.city,
                state: premiseContactFormData.state,
                zip: premiseContactFormData.zip,
                phone: premiseContactFormData.phone,
                cell: premiseContactFormData.cell,
                fax: premiseContactFormData.fax,
                email: premiseContactFormData.email,
                taxId: premiseContactFormData.taxId,
            },
        },
        copyContact: true,
    };
};

export const getContactName = (contact: ContactInboundData) => {
    const fullName = [contact.firstName, contact.lastName].filter((i) => !isEmpty(i)).join(" ");

    const fullAddress = [contact.address, contact.address_cont].filter((i) => !isEmpty(i)).join(" ");

    return [fullName, contact.company, fullAddress].filter((i) => !isEmpty(i)).join(", ");
};

/**
 * Return stored contact contactNumber if form data is equal to stored contact..
 * Otherwise, return undefined
 * */
export const getStoredContactState = (formData: any, customerContacts: ContactInboundData[], isContractorForm: boolean = false) => {
    if (!isEmpty(formData.storedContactNumber)) {
        const contact = customerContacts.find((c) => c.contactNumber === formData.storedContactNumber);
        if (contact) {
            const contactData = isContractorForm
                ? createContractorFormDataObjectFromContact(contact)
                : createContactFormDataObject(contact);
            const contactFormData = omit(formData, ["copyContact", "saveContact", "storedContactNumber"]);

            if (isEqual(contactFormData, contactData)) {
                return contact.contactNumber;
            }
        }
    }

    return undefined;
};

export const refreshRequiredFields = (formData: any, setRequiredFLC: (v: Array<string>) => void) => {
    const contactData = formData?.state?.formData?.section?.column1;
    let indicateRequired: Array<string> = [];
    if (contactData?.company || (contactData?.firstName && contactData?.lastName)) {
        indicateRequired = [];
    } else if (contactData?.firstName && !contactData?.lastName) {
        indicateRequired = ["lastname"];
    } else if (!contactData?.firstName && contactData?.lastName) {
        indicateRequired = ["firstname"];
    }
    setRequiredFLC(indicateRequired);
};
